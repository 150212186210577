<template>
	<div class="remind-detail">
		<Header></Header>
		<div class="wrapper">
			<el-breadcrumb separator="/" style="margin-top: 20px;">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>提醒列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="wrapper shadow" style="margin: 0 auto 20px;min-height: 718px;background: #FFF;">
			<div v-if="type != 3" class="info">
				<div class="info-title">{{typeName}}提醒</div>
				<div class="info-item">
					<span class="title">车牌号：</span>
					<div class="content">{{car.number}}</div>
				</div>
				<div class="info-item">
					<span class="title">车架号：</span>
					<div class="content">{{car.vin}}</div>
				</div>
				<div class="info-item">
					<span class="title">车主：</span>
					<div class="content">{{car.owner}}</div>
				</div>
				<div class="info-item">
					<span class="title">车主手机号：</span>
					<div class="content">{{car.phone}}</div>
				</div>
				<div class="info-item" v-if="type == 0">
					<span class="title">下次保养：</span>
					<div class="content">剩{{car.nextMileage - car.mileage}}公里</div>
				</div>
				<div class="info-item" v-if="type == 1">
					<span class="title">保险到期：</span>
					<div class="content">{{car.insuranceDate}}之前</div>
				</div>
				<div class="info-item" v-if="type == 2">
					<span class="title">年审到期：</span>
					<div class="content">{{car.inspectionDate}}之前</div>
				</div>
				<!-- <div class="info-item">
					<span class="title">备注：</span>
					<div class="content"></div>
				</div> -->
				<div class="info-item">
					<span class="title">提醒状态：</span>
					<div class="content" v-if="type == 0">
						<el-tag type="success" v-if="car.maintainTip == 1">已提醒</el-tag>
						<el-tag type="danger" v-if="car.maintainTip == 0">未提醒</el-tag>
					</div>
					<div class="content" v-if="type == 1">
						<el-tag type="success" v-if="car.insuranceTip == 1">已提醒</el-tag>
						<el-tag type="danger" v-if="car.insuranceTip == 0">未提醒</el-tag>
					</div>
					<div class="content" v-if="type == 2">
						<el-tag type="success" v-if="car.inspectionTip == 1">已提醒</el-tag>
						<el-tag type="danger" v-if="car.inspectionTip == 0">未提醒</el-tag>
					</div>
				</div>
			</div>
            <div v-if="type == 3" class="info">
                <div class="info-title">{{typeName}}</div>
                <div class="info-item">
                    <span class="title">车牌号：</span>
                    <div class="content">{{car.car.number}}</div>
                </div>
                <div class="info-item">
                    <span class="title">车架号：</span>
                    <div class="content">{{car.car.vin}}</div>
                </div>
                <div class="info-item">
                    <span class="title">联系人：</span>
                    <div class="content">{{car.contact}}</div>
                </div>
                <div class="info-item">
                    <span class="title">联系电话：</span>
                    <div class="content">{{car.phone}}</div>
                </div>
                <div class="info-item">
                    <span class="title">预约时间：</span>
                    <div class="content">{{car.appointmentTime}}</div>
                </div>
                <div class="info-item">
                    <span class="title">预约类型：</span>
                    <div class="content">{{appType(car.type)}}</div>
                </div>
                <div class="info-item" v-if="car.store">
                    <span class="title">预约门店：</span>
                    <div class="content">{{car.store.name}}</div>
                </div>
                <div class="info-item" v-if="car.store">
                    <span class="title">门店联系人：</span>
                    <div class="content">{{car.store.contact}}</div>
                </div>
                <div class="info-item" v-if="car.store">
                    <span class="title">门店联系电话：</span>
                    <div class="content">{{car.store.phone1}}</div>
                </div>
                <div class="info-item">
                    <span class="title">提醒状态：</span>
                    <div class="content">
                        <el-tag type="success" v-if="car.appointmentTip == 1">已提醒</el-tag>
                        <el-tag type="danger" v-if="car.appointmentTip == 0">未提醒</el-tag>
                    </div>
                </div>
            </div>
			<div class="info-btn">
				<el-button type="primary" @click="btnClick">提醒</el-button>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import http from '../../http.js'
	import Header from "../../components/header.vue"
	import Footer from "../../components/footer.vue"
	export default {
		name: 'Home',
		components: {
			Header,
			Footer
		},
		data() {
			return {
				car: {
					id: 0
				},
				type: 0,
				typeName: ''
			}
		},
		created() {
			if(this.$route.query.id){
				this.car.id = this.$route.query.id
			}
			if(this.$route.query.type){
				this.type = this.$route.query.type
			}
            if(this.type != 3) {
                this.getCar()
            }else{
                this.getAppointment()
            }
            this.getTypeName()
        },
        methods: {
            appType(type){
                let typeList = ['维修', '保养', '救援', '上门服务', '销售']
                return typeList[type-1]
            },
			getCar(){
				http.fetchGet('/api/admin/car', { id: this.car.id }).then((res) => {
					if (res.data.code == 0) {
						this.car = res.data.data
					} else {
						this.$message.error(res.data.desc)
					}
				})
			},
			getTypeName(){
				let types = ['保养到期', '保险到期', '年审到期', '预约提醒']
				this.typeName = types[this.type]
			},
            getAppointment(){
                http.fetchGet('/api/admin/order/sell', { id: this.car.id }).then((res) => {
                    if (res.data.code == 0) {
                        this.car = res.data.data
                    } else {
                        this.$message.error(res.data.desc)
                    }
                })
            },
			btnClick() {
				this.$confirm('确定要手动提醒吗？', '提醒', {
						distinguishCancelAndClose: true,
						confirmButtonText: '提醒',
						cancelButtonText: '取消'
					})
					.then(() => {
						http.fetchPost('/api/v2/admin/car_check/tip', { type: this.type, id: this.car.id }).then((res) => {
							if (res.data.code == 0) {
								this.getCar()
								this.$message({
									type: 'success',
									message: '提醒成功'
								});
							} else {
								this.$message.error(res.data.desc)
							}
						})
					})
					.catch(() => {
					});
			}

		},
	}
</script>
<style lang="scss">
	.remind-detail {
		.info {
			margin: 20px 0;
			box-sizing: 5px;
			padding: 30px;
			overflow: hidden;

			.info-title {
				font-size: 18px;
				font-weight: bold;
				text-align: center;
				margin: 0 auto 30px;
			}

			.info-item {
				display: flex;
				border: 1px solid rgba($color: $main-solid-color, $alpha: 0.6);
				border-bottom: none;

				&:last-child {
					border-bottom: 1px solid rgba($color: $main-solid-color, $alpha: 0.6);
				}

				.title {
					background: $main-bg-color;
					display: block;
					width: 150px;
					padding: 10px;
					text-align: right;
					border-bottom: 1px solid #FFF;
				}

				.content {
					padding: 10px;
					width: calc(100% - 150px);

					.el-tag {
						margin: 0 10px 0 0;
					}
				}
			}
		}

		.info-btn {
			text-align: center;
			margin: 20px 0 0 0;

			.el-button {
				width: 150px;
				font-size: 18px;
			}
		}
	}
</style>
